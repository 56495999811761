<template>
  <div>
    <van-nav-bar
      title="客户筛选"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <!--客服组件-->
    <customer @cusValue="chooseCus" />
    <!--是否为会员-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="isMember"
        :options="isMemberList"
        @change="chooseIsMember"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--是否注册-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="isRegister"
        :options="isRegisterList"
        @change="chooseisRegister"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--是否已添加好友-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="isAdd"
        :options="isAddList"
        @change="chooseIsAdd"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--是否已关注-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="isNotice"
        :options="isNoticeList"
        @change="chooseIsNotice"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--短信发送时间-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="sendTime"
        :options="sendTimeList"
        @change="chooseSendTime"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--会员到期时间-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="memberEndTime"
        :options="memberEndTimeList"
        @change="chooseMemberEndTime"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--登录时间-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
        :value="loginTime"
        :options="loginTimeList"
        @change="chooseLoginTime"
      ></van-dropdown-item>
    </van-dropdown-menu>
    <!--省市选择-->
    <province @province="provinceVal" @city="cityVal" />
    <van-field v-model="name" placeholder="姓名" :border="false" label="姓名" />
    <van-field
      v-model="lawfirm"
      placeholder="工作单位"
      :border="false"
      label="工作单位"
    />
    <van-field
      v-model="account"
      placeholder="手机号"
      :border="false"
      label="手机号"
    />
    <div>
      <van-button type="info" size="large" :square="true" @click="query"
        >筛选</van-button
      >
    </div>
    <van-divider
      contentPosition="center"
      customStyle="color: #1989fa; border-color: #1989fa;"
    >
      筛选结果:共 {{ total }} 条记录
    </van-divider>
    <van-checkbox-group v-model="checked">
      <van-cell-group inset>
        <van-cell
          v-for="(item, index) in list"
          :key="index"
          :title="item.userName"
          :value="item.phone"
          :label="item.orgName"
        >
          <template #right-icon>
            <van-checkbox
              shape="square"
              :name="item.phone"
              :value="item"
              @click.stop
              ref="checkPhone"
              @click="checkChange()"
            />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
    <div>
      <van-button
        type="info"
        size="normal "
        style="float: left; width: 50%"
        :square="true"
        @click="sendAll()"
        >全部{{ total }}发送</van-button
      >
      <van-button
        type="info"
        size="normal "
        style="width: 50%"
        :square="true"
        @click="sendChecked()"
        >已选{{ checkNum }}发送</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cusVal: 0, //选中客服
      isMember: "",
      isMemberList: [
        { text: "是否开通会员", value: "" },
        { text: "是", value: 1 },
        { text: "否", value: 0 }
      ], //是否开通会员
      isRegister: 1,
      isRegisterList: [
        { text: "已注册", value: 1 },
        { text: "未注册", value: 0 }
      ], //是否已注册
      isAdd: "",
      isAddList: [
        { text: "是否已添加好友", value: "" },
        { text: "是", value: 1 },
        { text: "否", value: 0 }
      ], //是否已添加好友
      isNotice: "",
      isNoticeList: [
        { text: "是否已关注", value: "" },
        { text: "是", value: 1 },
        { text: "否", value: 0 }
      ], //是否已关注
      sendTime: "",
      sendTimeList: [
        { text: "短信发送时间", value: "" },
        { text: "从未发送", value: 0 },
        { text: "三日未发送", value: 1 },
        { text: "五日未发送", value: 3 }
      ], //短信发送时间
      memberEndTime: "",
      memberEndTimeList: [
        { text: "会员到期时间", value: "" },
        { text: "会员一个月到期", value: 0 },
        { text: "会员三个月到期", value: 1 },
        { text: "会员一周到期", value: 2 },
        { text: "会员三日到期", value: 3 }
      ], //会员到期时间
      loginTime: "",
      loginTimeList: [
        { text: "登录时间", value: "" },
        { text: "从未登录", value: 0 },
        { text: "一日内登录", value: 1 },
        { text: "三日内登录", value: 2 },
        { text: "七日内登录", value: 3 }
      ], //登录时间
      province: "", //省
      city: "", //市
      name: "", //姓名
      lawfirm: "", //工作单位
      account: "", //手机号
      type: "",
      total: 0, //总记录数
      checked: [], //选中的记录
      list: [], //展示数据(10条)
      totalList: [], //所有数据
      checkboxRefs: [], //被选中的数据
      checkNum: 0 //已选条数
    };
  },
  methods: {
    //全部发送
    sendAll() {
      var sendList = [];
      this.totalList.forEach((item) => {
        sendList.push(item);
      });
      this.sendMessage(sendList);
    },
    //复选框点击事件
    checkChange() {
      this.checkboxRefs = [];
      this.$refs.checkPhone.forEach((item) => {
        if (item.checked) {
          this.checkboxRefs.push(item.value);
        }
      });
      this.checkNum = this.checkboxRefs.length;
    },
    //已选发送
    sendChecked() {
      this.sendMessage(this.checkboxRefs);
    },
    //短信发送
    sendMessage(list) {
      this.$axios
        .post(
          `${this.$base}/admin/weChat/sendMessageByPhone`,
          {
            users: list,
            type: this.type,
            isRegister: this.isRegister
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.$toast("操作成功!");
          }
        });
    },
    //数据查询
    query() {
      this.list = [];
      var data = {
        enterpriseWechatUserId: this.cusVal,
        memberStatus: this.isMember,
        isRegister: this.isRegister,
        isAddFriend: this.isAdd,
        isConcern: this.isNotice,
        isSend: this.sendTime,
        memberEndTime: this.memberEndTime,
        isLogin: this.loginTime,
        city: this.city,
        province: this.province,
        userName: this.name,
        orgName: this.lawfirm,
        phone: this.account,
        type: this.type
      };
      this.$axios
        .post(`${this.$base}/admin/weChat/query`, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          if (res.code === 200) {
            this.total = res.data.total;
            res.data.userList.forEach((item, index) => {
              //数据只展示10条
              if (index < 10) {
                this.list.push(item);
              }
              this.totalList.push(item);
            });
          }
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    //省
    provinceVal(val) {
      this.province = val;
    },
    //市
    cityVal(val) {
      this.city = val;
    },
    //选中客服触发
    chooseCus(val) {
      this.cusVal = val;
    },
    //是否为会员
    chooseIsMember(val) {
      this.isMember = val;
    },
    //是否已注册
    chooseisRegister(val) {
      this.isRegister = val;
    },
    //是否已填加好友
    chooseIsAdd(val) {
      this.isAdd = val;
    },
    //是否已关注
    chooseIsNotice(val) {
      this.isNotice = val;
    },
    //短信发送时间
    chooseSendTime(val) {
      this.sendTime = val;
    },
    //会员到期时间
    chooseMemberEndTime(val) {
      this.memberEndTime = val;
    },
    //登录时间
    chooseLoginTime(val) {
      this.loginTime = val;
    }
  },
  mounted() {
    this.type = this.$route.query.type;
  }
};
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
